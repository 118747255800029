import * as React from 'react';
import { MainLayout } from 'components/MainLayout';
import { ContentTitle } from 'components/ContentTitle';
import { Footer } from 'components/Footer';

const AboutApp = () => (
  <MainLayout>
    <div className="container px-2">
      <ContentTitle title="Как это работает" backButton />
      <div className="mb-10">
        <div>
          <h1 className="font-semibold text-lg">1. Установите приложение</h1>
          <p className="mt-2">
            Нам не нужно много ваших персональных данных, чтобы быть ближе. Больше всего нам важно ваше свободное время,
            поэтому регистрация будет быстрая.
            <br /> Просто установите приложение, укажите имя/никнейм, номер телефона и адрес доставки.
            <br /> Для удобства и безопасности оплаты нам еще понадобится ваш номер карты, он вводится в личном
            кабинете.
            <br /> Также вы можете сделать покупку прямо на сайте.
          </p>
        </div>
        <div className="mt-10">
          <h1 className="font-semibold text-lg">2. Составьте список покупок</h1>
          <p className="mt-2">
            У нас уже есть почти все самое необходимое и со временем количество позиций будет увеличиваться исходя из
            ваших пожеланий.
            <br /> Зелень, овощи, фрукты, молочные продукты, мясо и хлеб – узнайте о товаре, положите его в корзину и
            оформите заказ в один клик. Через несколько часов вы получите пакет с самыми свежими продуктами в городе.
          </p>
        </div>
        <div className="mt-10">
          <h1 className="font-semibold text-lg">3. Отслеживайте заказ</h1>
          <p className="mt-2">
            После оформления заказа ваши покупки становятся нашей заботой. Мы аккуратно собираем и упаковываем все по
            списку и отправляем вам курьера. Если планы изменились, вы всегда можете отложить доставку, изменить адрес и
            проверить статус заказа.
          </p>
        </div>
        <div className="mt-10">
          <h1 className="font-semibold text-lg">4. Примите посылку</h1>
          <p className="mt-2">
            Курьер привезет заказ точно в срок – проверьте, все ли укомплектовано верно, и положите пару часов в копилку
            сохраненного времени 😉
          </p>
        </div>
      </div>
    </div>
    <Footer />
  </MainLayout>
);

export default AboutApp;
